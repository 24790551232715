import VueComponent, {data, method, prop} from '../../adapters/VueComponent';
import {eventToFiles} from '../../utils/utils';
import {html} from './FileUpload.html';
import {uuid4} from '../../utils/uuid';
import {Services} from '../../services/Services';
import {Http} from '../../services/Http';
import {IPromise} from '../../utils/SimplePromise';
import {FileInputRef} from '../../utils/Refs';


class FileUploadController extends VueComponent {

    @prop()
    url: string;

    @prop()
    formData: (file: File) => FormData;

    @prop()
    success: (response: any, file?: File) => {};

    @prop()
    failure: (error: any, file?: File) => {};

    @prop()
    disableVector: boolean;

    @prop()
    disableRaster: boolean;

    @prop()
    allFiles: boolean;

    // Used when we want to hold onto the file until the user presses some other kind of submit button
    @prop()
    delayedSubmission: IPromise<any>;

    @prop()
    secondary: boolean;

    @prop()
    directUpload: File;

    @data({
        ref: FileInputRef
    })
    file: File;

    @data()
    uuid;

    @data()
    percent_loaded: number;

    @data()
    uploading: boolean;

    @data()
    upload_failed: boolean;

    @data()
    upload_success: boolean;

    @data()
    dragging_file: boolean;

    abort_controller: AbortController;

    allowed_extensions = [
        'psd',
        'pdf',
        'ai',
        'eps',
        'tif',
        'tiff',
        'png',
        'jpg',
        'jpeg',
        'gif',
        'bmp',
        'webp',
        'heic',
        'avif'
    ]

    raster_extensions = [
        'png',
        'jpg',
        'gif',
        'webp',
        'heic',
        'avif'
    ]

    constructor(component) {
        super(component);
        this.uuid = uuid4();
    }

    override mounted() {
        super.mounted();

        if (this.directUpload) {
            this.upload();
        }
        if (this.delayedSubmission) {
            this.delayedSubmission.then(() => {
                this.upload();
            })
        }
    }

    @method()
    paste($event) {
        if ($event.clipboardData.files.length == 0) {
            return;
        }

        for (const file of $event.clipboardData.files) {
            let parts = file.name.split('.');
            let extension = parts[parts.length - 1];
            if (this.allowed_extensions.indexOf(extension) !== -1) {
                this.file = file;
                if (this.delayedSubmission) {
                    this.delayedSubmission.then(() => {
                        this.upload();
                        this.$forceUpdate();
                    })
                }
                else {
                    this.upload();
                }
                break;
            }
        }
    }

    @method()
    drop($event) {
        let files = eventToFiles($event);
        if (files.length > 0) {
            this.file = files[0];

            if (this.delayedSubmission) {
                this.delayedSubmission.then(() => {
                    this.upload();
                    this.$forceUpdate();
                })
            }
            else {
                this.upload();
            }
        }
    }

    upload() {
        if (!this.url) {
            this.resetState();
            return;
        }

        // Already uploading
        if (this.abort_controller) {
            this.resetState();
            return;
        }

        let form = null;
        this.percent_loaded = 0;
        this.upload_failed = false;
        this.upload_success = false;
        this.uploading = true;

        if (this.formData) {
            form = this.formData(this.uploadFile());
        }
        else {
            form = new FormData();
            form.append('file', this.uploadFile());
        }

        if (!form) {
            this.resetState();
            return;
        }

        this.abort_controller = new AbortController();
        Services.get<Http>('$http').request({
            url: this.url,
            data: form,
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            signal: this.abort_controller.signal,
            onUploadProgress: (progressEvent) => {
                if (progressEvent.total) {
                    this.percent_loaded = (progressEvent.loaded / progressEvent.total) * 100;
                }
                else {
                    this.percent_loaded = 0
                }
            }
        }).then((response) => {
            this.uploading = false;
            this.upload_success = true;
            this.abort_controller = null;
            if (this.success) {
                this.success(response, this.uploadFile());
            }
        }, (error) => {
            this.uploading = false;
            this.upload_failed = true;
            this.abort_controller = null;
            if (this.failure) {
                this.failure(error, this.uploadFile());
            }
        });
    }

    @method()
    dragEnter() {
        this.dragging_file = true;
    }

    @method()
    dragLeave() {
        this.dragging_file = false;
    }

    @method()
    fileTypeDisplay() {
        if (this.allFiles) {
            return ''
        }

        let duplicates = ['jpeg', 'tiff'];
        if (this.disableVector) {
            return this.raster_extensions.filter(v => duplicates.indexOf(v) === -1).join(', ');
        }
        if (this.disableRaster) {
            return this.allowed_extensions.filter(v => this.raster_extensions.indexOf(v) === -1).filter(v => duplicates.indexOf(v) === -1).join(', ');
        }
        return this.allowed_extensions.filter(v => duplicates.indexOf(v) === -1).join(', ');
    }

    @method()
    fileAcceptList() {
        if (this.allFiles) {
            return null;
        }
        if (this.disableVector) {
            return this.raster_extensions.map(v => '.' + v).join(',');
        }
        if (this.disableRaster) {
            return this.allowed_extensions.filter(v => this.raster_extensions.indexOf(v) === -1).map(v => '.' + v).join(',');
        }
        return this.allowed_extensions.map(v => '.' + v).join(',');
    }

    @method()
    fileSize() {
        let file = this.uploadFile()
        if (!file || !file.size) {
            return;
        }

        let bytes = file.size;
        let decimals = 1;

        const k = 1024;
        const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`;
    }

    @method()
    cancelUpload() {
        if (this.abort_controller) {
            // This will cause the upload promise to fail/reject so lets handle everything there
            this.abort_controller.abort();
        }
    }

    @method()
    resetState() {
        this.file = null;
        this.uploading = false;
        this.percent_loaded = 0;
        this.upload_failed = false;
        this.upload_success = false;
        this.abort_controller = null;
    }

    @method()
    uploadFile() {
        return this.file || this.directUpload;
    }
}


export default function FileUpload() {
    return {
        tag: 'file-upload',
        template: html,
        controller: FileUploadController
    }
}